.treatment-content {
    padding: 80px 0;
    background-color: $BG_PRIMARY;


    @include tablet {
        padding: 40px 0;
    }

    &__container {
        @include flexbox;
        @include justify-content(space-between);


        @include tablet {
            @include flex-direction(column);
        }
    }

    &__column {
        &--one {
            max-width: 425px;
            width: 100%;
            padding-right: 15px;

            @include tablet {
                padding-right: 0;
                max-width:  100%;
            }
        }

        &--two {
            max-width: 508px;
            width: 100%;

            @include above-tablet {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            @include tablet {
                max-width:  100%;
            }
        }
    }

    &__title {
        font-size: 46px;
        line-height: 66px;
        font-weight: $WEIGHT_MEDIUM;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    &__image {
        @include tablet {
            width: 100%;
        }
    }

    &__content {
        @include above-tablet {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        p {
            margin-bottom: 12px;
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: 400px;
            margin-top: 20px;
        }

        hr {
            margin: 30px 0;
            background-color: #B68C87;
            border: none;
        }

        table {
            margin-top: 10px;

            td {
                color: #B68C87;
                font-weight: $WEIGHT_LIGHT;
            }
        }
    }

    &__button {
        margin-top: 20px;
        align-self: baseline;

        &.button {
            display: inline-block;
        }
    }
}