.treatments {
    text-align: center;
    padding: 60px 0 60px 0;
    background: $BG_PRIMARY;

    &__title {
        font-size: 36px;
        margin-bottom: 62px;
    }

    &__container {
        @include small-desktop {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__control-slide {
        cursor: url('../../img/circle.png'), auto !important;	

        &.swiper-slide-thumb-active {
            .treatment__icon {
                opacity: 1;
            }
        }
    }

    .treatment {
        &__icon {
            opacity: .5;
            max-width: 72px;
            pointer-events: none;
        }

        &__title {
            font-weight: $WEIGHT_LIGHT;
            font-size: 18px;
            padding: 0 38px;
        
            @include mobile-large {
                padding: 0 15px;
            }
        }

        &__text {
            margin: 0 auto;
            max-width: 600px;
            text-align: center;
            padding: 98px 0 0 0;
    
            @include small-desktop {
                padding: 98px 15px 0 15px;
            }
        }

        &__button {
            max-width: 332px;
            margin: 50px auto 0 auto;
        }
    }
}
