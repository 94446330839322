.header {
    height: 238px;

    @include tablet {
        height: auto;
    }

    &__top {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        padding: 40px 15px;
        background: #FFF;

        @include tablet {
            @include justify-content(center);
            padding: 24px 15px;
            z-index: 10;
            position: relative;
            border-bottom: 1px solid #F9F3F2;
        }
    }

    &__logo {
        max-width: 248px;
        width: 100%;

        @include tablet {
            max-width: 149px;
        }
    }

    &__nav {
        position: relative;
        border-top: 1px solid #F9F3F2;
        border-bottom: 1px solid #F9F3F2;
        background-color: #FFF;

        @include tablet {
            display: none;
        }
    }

    &__menu {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        height: 72px;

        li {
            @include flexbox;
            @include align-items(center);
            height: 100%;
        }

        a {
            font-weight: $WEIGHT_MEDIUM;
            text-transform: uppercase;
            color: #B68C87;
            margin: 0 45px;
            transition-duration: 400ms;
            opacity: .5;

            @include hover {
                opacity: 1;
            }

            @include small-desktop {
                margin: 0 30px;
            }
        }

        .current-menu-item a {
            opacity: 1;
        }
    }

    &__button {
        &.button {
            position: relative;

            @include tablet {
                display: none;
            }
        }
    }

    &__burger {
        @include appearance(none); 
        border: none;
        background-color: transparent;
        display: none;
        position: absolute;
        padding: 15px;
        left: 0;

        &.active {
            transform: rotate(45deg);
        }

        @include tablet {
            display: block;
        }

        &-bar {
            height: 2px;
            border-radius: 10px;
            background-color: #B68C87;
            margin-bottom: 3px;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                transition-duration: 500ms;
            }

            &:nth-child(1) {
                width: 18px;
            }

            &:nth-child(2) {
                width: 13px;

                &:before {
                    content: '';
                    height: 2px;
                    border-radius: 10px;
                    background-color: #B68C87;
                    margin-bottom: 3px;
                    display: block;
                }
            }

            &:nth-child(3) {
                width: 9px;
            }

            .active & {

                &:nth-child(1), &:nth-child(3) {
                    opacity: 0;
                }

                &:nth-child(1),  &:nth-child(2), &:nth-child(3) {
                    transform: rotate(90deg);
                    width: 18px;

                    &:before {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }

    .sticky-header-active & {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #FFF;
        z-index: 5;
    }

    @include above-tablet {
        li.menu-item-has-children {
            ul.sub-menu {
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);
                margin: 0;
                height: 62px;
                position: absolute;
                left: 0;
                background-color: #B68C87;
                width: 100%;
                top: 100%;
                opacity: 0;
                transition-duration: 800ms;
                top: calc(100% - 60px);
                z-index: 5;
                pointer-events: none;

                a {
                    color: #FFF;
                    font-size: 12px;
                    margin: 0 12px;
                    opacity: .5;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &:hover {
                ul.sub-menu {
                    z-index: 5;
                    height: 62px;
                    opacity: 100;
                    top: 100%;
                    pointer-events: initial;
                }
            }
        }
    }
}