.image-slider {
    $parent: '&';

    background-color: $BG_PRIMARY;
    padding: 130px 0;

    &__title {
        text-transform: uppercase;
        font-size: 26px;
    }

    &__container {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        margin-bottom: 40px;
    }

    &__control {
        @include flexbox;
        @include justify-content(space-between);
        max-width: 60px;
        width: 100%;
    }

    &__wrapper {
        padding: 0 0 0 10px;
        width: 100%;
    }

    &__slide {
        width: 300px;
        height: 300px;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-color: #f3f3f3;
        background-size: cover;
        background-position: center;
    }

    @include max-width(1270px) {
        padding: 100px 0;

        #{$parent}__wrapper {
            max-width: 100%;
            width: 100%;
            margin-left: 0;
            padding-left: 15px;
        }
    }

    @include tablet {
        padding: 60px 0;

        #{$parent}__container {
            margin-bottom: 20px;
        }

        #{$parent}__title {
            font-size: 18px;
        }

        #{$parent}__slide {
            width: 200px;
            max-width: 200px;
            min-width: 200px;
            height: 200px;
        }
    }
}