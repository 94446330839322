.swiper-pagination-bullet {
    @include flexbox;
    opacity: .7;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin: 0 5px;
    background-color: #B68C87;

    &.swiper-pagination-bullet-active {
        height: 10px;
        width: 10px;
        opacity: 1;
    }
}

.swiper-button-next,
.swiper-button-prev {
    position: relative;
    display: flex;
    align-items: center;

    svg {
        stroke: #B68C87;
    }

    &:after {
        background: $BG_PRIMARY;
        opacity: 0;
        content: "";
        left: 0;
        right: 0;
        height: 10px;
        width: 10px;
        position: absolute;
        margin: 0 auto;
        display: block;
        border-radius: 50%;
        transition-duration: 200ms;
        z-index: 1;
    }

    @include hover {
        &:after {
            opacity: 1;
            transform: scale(5, 5);
        }
    }

    &.swiper-button-disabled {
        svg {
            stroke: #C7C7C7;
        }

        &:after {
            content: none;
        }
    }
}