.mobilemenu {
    display: none;
}
@include tablet {
    .mobilemenu {
        @include flexbox;
        @include flex-direction(column);
        transform: translateY(-100%);
        position: fixed;
        background: #FFFAF9;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
        padding-top: 101px;
        text-align: center;
        transition-duration: 800ms;
        opacity: 0;

        &.active {
            top: 0;
            opacity: 1;
            transform: translateY(0)
        }

        &__menu-wrapper {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(center);
            @include flex(1);

            li {
                position: relative;
                opacity: 0;
                transition-duration: 1.5s;
                @include transform(translateY(-60px));
                .active & {
                    opacity: 1;
                    @include transform(translateY(0px));
                }

                &.active {
                    > a {
                        opacity: 1;
                    }

                    ul.sub-menu {
                        height: auto;
                    }
                }
            }

            a {
                font-size: 16px;
                color: #B68C87;
                font-weight: $WEIGHT_MEDIUM;
                text-transform: uppercase;
                padding: 12px 0;
                display: block;
                opacity: .5;
            }

            ul.sub-menu {
                height: 0;
                overflow: hidden;
                margin: 0;

                li {
                    
                    a {
                        text-transform: capitalize;
                        font-size: 14px;
                        padding: 8px 0;
                    }

                    .active & {
                        height: auto;
                    }
                }
            }
        }

        &__button {
            &.button {
                padding: 28px;
                font-size: 16px;

                opacity: 0;
                transition-duration: 1.5s;
                transition-delay: 500ms;

                .active & {
                    opacity: 1;
                }
            }
        }
    }
}