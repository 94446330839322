.contact-form {
    padding: 60px 0;
    text-align: center;

    .single-treatment & {
        padding-top: 0;
    }

    @include tablet {
        background-color: $BG_PRIMARY;
    }

    &__title {
        text-transform: uppercase;
        font-size: 28px;
        margin-bottom: 30px;
    }

    &__text {
        font-size: 18px;
        max-width: 497px;
        width: 100%;
        margin: 0 auto;
        color: #E6BFBA;
        line-height: 28px;
    }

    &__form-inner {
        @include flexbox;
        @include justify-content(space-between);
        padding: 60px 0 20px 0;

        .treatment-content__content & {
            padding: 20px 0 20px 0;
        }

        @include tablet {
            @include flex-direction(column);
        }
    }

    input[type='submit'].wpcf7-form-control.wpcf7-submit.button {
        position: absolute; 
        transition-duration: 400ms;

        @include hover {
            background-color: #DAAFA7;
        }
    }

    &__column {
        width: 48%;

        .treatment-content__content & {
            width: 100%;

            .button {
                max-width: 180px;
            }
        }

        @include tablet {
            width: 100%;
        }

        input {
            margin-bottom: 30px;
        }

        textarea {
            resize: none;
            height: 92px;
        }

        input, textarea {
            width: 100%;
            background-color: transparent;
            border: none;
            color: #B68C87;
            border-bottom: 1px solid #E6BFBA;
            border-radius: 0;

            &:focus, &:active {
                outline: none;
                color: #B68C87;
            }

            &::placeholder {
                color: #E6BFBA;
                opacity: .7;
            }
        }
    }

    form > p {
        @include flexbox;
        @include flex-direction(row-reverse);
        @include justify-content(space-between);
        @include align-items(center);
        margin-bottom: 20px;

        @include tablet {
            @include flex-direction(column);

            input[type="submit"] {
                width: 100%;
            }
        }
    }

    .ajax-loader {
        margin-left: 0;

        @include tablet {
            margin-top: 10px;
        }
    }

    .wpcf7-file {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .wpcf7-file::-webkit-file-upload-button {
        visibility: hidden;
      }

      .wpcf7-file::before {
        content: 'UPLOAD FILES';
        display: inline-block;
        background: linear-gradient(top, #f9f9f9, #e3e3e3);
        border: none;
        border-radius: 0;
        padding: 5px 8px;
        margin-bottom: 3px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid #E6BFBA;
        
        @include hover {
            background-color:#E6BFBA;
            border: none;
            color: #B68C87;
            border: none;
            border-color: none;
            margin: 0;
            border: 1px solid #E6BFBA;
        }
      }
      .wpcf7-file:hover::before {
        border-color: #E6BFBA;
        background-color: #E6BFBA;
        color: white;
    }
      .wpcf7-file:active::before {
        background-color: #E6BFBA;
        color: white;
      }
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output, .wpcf7 form.sent .wpcf7-response-output {
    background-color:#E6BFBA;
    border: none;
    color: #B68C87;
    border: none;
    border-color: none;
    margin: 0;
}

.wpcf7-form-control-wrap {
    position: relative;
}

.wpcf7-not-valid-tip {
    position: absolute;
    top: 0;
    font-size: 12px;
    right: 0;
}