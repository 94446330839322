.hero-slider {
    background: $BG_PRIMARY;
    height: 568px;
    position: relative;
    width: 100%;

    @include medium-desktop {
        height: 468px;
    }

    @include small-desktop {
        @include flex-direction(column);
    }

    @include tablet {
        height: auto;
    }

    &__images, &__content {
        width: 50%;
        height: 100%;

        @include tablet{
            width: 100%;
        }
    }

    &__images {
        position: absolute;

        @include tablet{
            height: 400px;
            position: static;
        }

        @include mobile-large {
            height: 275px;
        }
    }

    &__images-slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__content {
        margin-right: 0;
        position: absolute;
        max-width: 640px;
        padding-right: 15px;
        left: 50%;

        @include tablet{
            left: 0;
            padding: 40px 15px 100px 15px;
            position: static;
            margin-right: auto;
        }

        @include mobile-large {
            padding: 30px 15px 100px 15px;
        }
    }

    &__content-slide {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        text-align: center;
    }

    &__content-wrapper {
        max-width: 353px;
        width: 100%;

        h1, h2, h3, h4, h5, h6 {
            text-transform: uppercase;
            font-size: 26px;
            margin-bottom: 36px; 
        }

        p {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 30px;
        }
    }

    &__pagination {
        display: none;

        @include small-desktop {
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            position: absolute;
            width: 140px;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 50px;

            @include tablet{
                bottom: -50px;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 8;

        &.swiper-button-prev {
            left: 40px;
        }

        &.swiper-button-next {
            right: 40px;
        }

        @include small-desktop {
            top: auto;
            transform: none;
            bottom: 40px;
        }
    }
}