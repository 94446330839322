.icons {
    padding: 80px 15px;
}

.icons__title {
    margin-bottom: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid $COLOR_PRIMARY_DARK;
    max-width: 100%;
}

.icons__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 18px 110px;

    @include above-tablet {
        grid-template-columns: repeat(2, 1fr);
    }
}

.icons__item {
    display: flex;
    align-items: center;
    gap: 28px;
    padding: 12px 0;
}

.icons__icon {

}

.icons__text {

}
