.footer {
    background-color: $COLOR_PRIMARY_ALT;
    @include tablet {
        padding: 0 15px;
    }

    .treatment-template-default &, .page-id-194 &, .page-id-112 & {
        background-color: #FFF;
    }

    &__top {
        @include flexbox;
        @include justify-content(center);
        padding: 30px 15px;
        border-bottom: 1px solid #E6BFBA;
    }

    &__logo {
        max-width: 248px;
        width: 100%;
    }

    &__middle {
        @include flexbox;
        padding: 30px 15px;

        @include tablet {
            @include flex-direction(column);
            width: 100%;
        }
    }

    &__list-title {
        color: #B68C87;
        text-transform: uppercase;
        font-size: 18px;
    }

    &__list {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        text-align: center;
        width: calc(100% / 4);

        h4 {
            margin-bottom: 16px;
        }

        ul li {
            margin: 12px 0;
        }

        a {
            color: #E6BFBA;
            transition-duration: 400ms;

            @include hover {
                color: #B68C87;
            }
        }

        @include tablet {
            width: 100%;
            margin-top: 25px;
        }
    }

    &__bottom {
        @include flexbox;
        @include justify-content(space-between);
        padding: 30px 15px;
        border-top: 1px solid #E6BFBA;

        a, p {
            color: #E6BFBA;
        }

        a {
            transition-duration: 400ms;

            @include hover {
                color: #B68C87;
            }
        }

        @include tablet {
            @include flex-direction(column);
            width: 100%;
            text-align: center;
        }
    }

    &__copyright {
        @include tablet {
            margin-bottom: 25px;
        }
    }

    &__legal {
        @include flexbox;

        li {
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }

        @include tablet {
            @include flex-direction(column);
            width: 100%;
            text-align: center;

            li {
                margin-right: 0;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}