h1, h2, h3, h4, h5, p, a, body, html {
    font-family: $FONT_PRIMARY;
}

h1, h2, h3, h4, h5 {
    font-family: $FONT_PRIMARY;
    font-weight: $WEIGHT_REGULAR;
    color: #B68C87;
    letter-spacing: 0px;
    margin: 0;
}

p, a {
    font-weight: $WEIGHT_LIGHT;
    color: #B68C87;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0px;
    margin: 0;
}