.button {
    font-family: $FONT_PRIMARY;
    font-weight: $WEIGHT_MEDIUM;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px 25px;
    letter-spacing: 1px;
    transition-duration: 400ms;
    overflow: hidden;
    position: relative;

    span {
        z-index: 2;
        position: relative;
    }

    &:after {
        content: '';
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        background: transparent;
        transition-duration: 400ms;
        z-index: 1;
    }

    @include hover {
        &:after {
            @include transform(scale(500));
        }
    }

    &--primary {
        background: $COLOR_PRIMARY;
        color: #FFF;

        &:active, &:visited {
            color: #FFF;
        }

        @include hover {
            color: #FFF;

            &:after {
                background: #b9958d;
            }
        }
    }

    &--outline {
        border: 1px solid $COLOR_PRIMARY;
        color: $COLOR_PRIMARY;

        &:active, &:visited {
            color: $COLOR_PRIMARY;
        }

        @include hover {
            color: #FFF;

            &:after {
                background: $COLOR_PRIMARY;
            }
        }
    }
}

input[type='submit'] {
    @extend .button;
    @extend .button--outline;
    @include appearance(none);
    border-radius: 0;
    padding: 12px 25px;
    border: none;
    background: transparent;
    border: 1px solid $COLOR_PRIMARY;
    overflow: hidden;
    position: relative;

    &:focus, &:active, &:visited {
        outline: none;
        border: 1px solid $COLOR_PRIMARY;
    }

    @include hover {
        color: #FFF;
        border: 1px solid $COLOR_PRIMARY;

        &:after {
            @include transform(scale(500));
            background: $COLOR_PRIMARY;
        }
    }
}
