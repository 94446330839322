.text-image {
    $parent: '.text-image';

    padding: 80px 15px;

    &__container {
        @include flexbox;
        gap: 20px;

        @include above-tablet {
            gap: 60px;
            
            .text-image--half & {
                gap: 100px;
            }
        }

        #{$parent}--image-left & {
            @include flex-direction(row-reverse);
        }
    }

    &__column {
        @include flexbox;
        @include align-items(center);
        text-align: center;
        width: 100%;

        @include above-tablet {

            &:first-child {
                max-width: 332px;
            }
        }

        .text-image--half & {
            @include above-tablet {
                width: 50%;

                &:first-child {
                    max-width: 100%;
                }
            } 
        }
    }

    &__text {
        position: relative;
        margin-left: 0;
        // max-width: 332px;

        .container--lg & {
            max-width: 100%;
        }

        h1, h2 {
            margin-bottom: 36px;
            font-size: 28px;
        }

        h3, h4, h5, h6 {
            margin-bottom: 8px;
            font-size: 18px;
        }

        p {
            margin-bottom: 30px;
        }

        &--left {
            text-align: left;
        }
    }

    &__text-background {
        top: calc(-25% - 10px);
        left: calc(-25% + 10px);
        width: 225px;
        height: 225px;
        background-size: cover;
        position: absolute;
    }

    &__button {
        margin-top: 40px;
    }

    &__image {
        max-width: 639px;
        width: 100%;
        margin-right: 0;

        // @include above-tablet {
        //     width: 80%;
        //     margin-left: 20%;
        // }
    }

    &__map {
        // max-width: 464px;
        width: 100%;
        margin-right: 0;
        height: 464px;

        .container--lg & {
            // width: 60%;
            height: 555px;

            // @include above-tablet {
            //     width: 555px;
            // }
        }
    }

    @include small-desktop {
        &#{$parent}--image-left {
            #{$parent}__text {
                padding-left: 15px;
            }
        }

        &#{$parent}--image-right {
            #{$parent}__text {
                padding-right: 15px;
            }
        }
    }

    @include tablet {
        padding: 0;
        background-color: #FFF;

        #{$parent}__container {
            @include flex-direction(column-reverse);
            padding: 0;
        }

        #{$parent}__text-background {
            display: none;
        }

        #{$parent}__image {
            max-width: 100%;
        }

        #{$parent}__map {
            max-width: 100%;
        }

        #{$parent}__text {
            margin: 0 auto;
            padding: 40px 15px;
            max-width: 100%;
        }
    }
}