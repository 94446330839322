body.page {
    margin: 0;
    padding: 0;
}

.container--lg {
    max-width: 1252px;
    margin: 0 auto;
    padding: 0 15px;
}

.container--sm {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1013px;
}

.no-scroll {
    overflow: hidden;
}

.flex {
    @include flexbox;
}

.bg--primary {
    background-color: $BG_PRIMARY;
}

.bg--white {
    background-color: white;
}