.location {
    padding: 80px 0;

    @include tablet {
        padding: 40px 0;
    }

    &__container {
        @include flexbox;

        @include tablet {
            @include flex-direction(column-reverse);
        }

        h1, h2, h3 {
            font-size: 28px;
            margin-bottom: 20px;
            margin-top: 50px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__column {
        @include flexbox;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            @include flex(60%);
        }

        &:last-child {
            @include flex(40%);
        }

        @include tablet {
            &:first-child, &:last-child {
                @include flex(100%);
            }
        }
    }

    &__details {
        @include flexbox;
        @include flex-direction(column);
        max-width: 335px;
        width: 100%;
        
        &--address {
            margin-bottom: 25px;
        }

        &--email {
            margin-bottom: 10px;
        }

        &--phone {
            margin-bottom: 60px;
        }

        a {
            transition-duration: 400ms;
            @include hover {
                color: #B68C87;
            }

            &:active, &:focus, &:hover {
                color: #B68C87;
            }
        }
    }

    &__opening-times {
        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__item {
        @include flexbox;
        @include justify-content(flex-start);
        margin-bottom: 4px;
    }

    &__day, &__time {
        @include flex(50%);
    }

    &__image {
        padding-right: 60px;
        height: 503px;
        margin-top: 30px;

        @include above-tablet {
            margin-top: 0;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        @include tablet {
            padding-right: 0;
        }
    }
}