@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/components/effect-fade/effect-fade.scss';
@import '../../node_modules/aos/dist/aos.css';

@import 'base/reset';
@import 'base/variables';
@import 'base/fonts';

@import 'helpers/mixins';
@import 'helpers/helpers';

@import 'global/header';
@import 'global/footer';
@import 'global/mobilemenu';

@import 'modules/swiper';

@import 'partials/social';
@import 'partials/contact-form';
@import 'partials/hero-slider';
@import 'partials/text-image';
@import 'partials/treatments-slider';
@import 'partials/testimonials';
@import 'partials/before-after';
@import 'partials/treatment-content';
@import 'partials/faqs';
@import 'partials/image-slider';
@import 'partials/image';
@import 'partials/location';
@import 'partials/logos';
@import 'partials/icons';

@import 'components/cursor';
@import 'components/button';

@import 'templates/default';
