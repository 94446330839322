.logos {
    $parent: '&';

    &__content {
        @include flexbox;
        @include justify-content(space-between);
        padding-top: 60px;
    }

    &__title {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 21px;
    }

    &__text {

    }

    &__split {
        &:first-child {
            max-width: 507px;
            width: 100%;
        }

        &:last-child {
            text-align: right;
        }

        &--qualifications {
            @include flexbox;
            @include flex-wrap;
        }
    }

    &__logos {
        @include flexbox;
        @include justify-content(space-between);
        padding: 60px 0;
    }

    &__logo {
        max-width: 174px;
    }

    &__qualification {
        font-size: 18px;

        @include flex(50%);
    }

    @include tablet {
        #{$parent}__content {
            @include flex-direction(column);
        }

        #{$parent}__split {
            @include flex(50%);
        }

        #{$parent}__logos {
            @include flex-wrap;
            @include align-items(center);
        }

        #{$parent}__logo {
            max-width: 50%;
            margin-bottom: 20px;
        }

        #{$parent}__split {
            &:last-child {
                margin-top: 40px;
                text-align: left;
            }
        }
    }

    @include mobile-large {
        #{$parent}__qualification {
            @include flex(100%);
            margin-bottom: 10px;
        }
    }
}