$FONT_PRIMARY: 'Jost', sans-serif;

$WEIGHT_LIGHT: 300;
$WEIGHT_REGULAR: 400;
$WEIGHT_MEDIUM: 500;
$WEIGHT_SEMIBOLD: 600;

$COLOR_PRIMARY: #DAAFA7;
$COLOR_PRIMARY_ALT: #FFFAF9;
$COLOR_PRIMARY_DARK: #E6BFBA;

$BG_PRIMARY: rgba(230, 191, 186, 0.1);

/* ---------------------------------------------
    Screen sizes
--------------------------------------------- */
$size-mobile-small: 320px;
$size-mobile: 480px;
$size-mobile-large: 600px;
$size-tablet: 768px;
$size-small-desktop: 1024px;
$size-medium-desktop: 1200px;
$size-large-desktop: 1440px;

$size-medium-height: 800px;
$size-small-height: 700px;

$size-container: 1320px;
$size-container-sm: 1090px;
$size-half-container: calc((100vw - #{$size-container}) / 2);
$minus-size-half-container: calc((-100vw + #{$size-container}) / 2);
