.default-content {
    text-align: center;
    padding: 40px 0;
    background-color: $BG_PRIMARY;

    &__title {
        font-size: 26px;
        margin-bottom: 24px;
    }

    &__content {
        h2, h3, h4, h5 {
            font-size: 20px;
            margin: 2px 0 22px 0;
        }

        p {
            font-size: 18px;
            font-weight: $WEIGHT_SEMIBOLD;
            line-height: 24px;
            margin-bottom: 22px;
        }
    }
}