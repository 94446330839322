.faqs {
    $parent: '&';

    padding: 77px 15px;
    background: $BG_PRIMARY;

    &__title {
        max-width: 791px;
        margin: 0 auto;
        text-align: center;
        font-size: 26px;
        margin-bottom: 100px;
    }

    &__wrapper {
        max-width: 791px;
        margin: 0 auto;
    }

    &__item {
        margin-top: 25px;
        display: block;
    }

    &__question {
        @include flexbox;
        @include justify-content(space-between);
        appearance: none;
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        border-bottom:  1px solid #E6BFBA;
        border-radius: 0;
        width: 100%;
        text-align: left;

        &:focus, &:active {
            border-bottom:  1px solid #E6BFBA;
        }

        h5 {
            font-weight: $WEIGHT_MEDIUM;
            color: #B68C87;
            font-size: 16px;
            padding-bottom: 10px;
        }

        &:hover {
            border-bottom-color: #E6BFBA;
        }
    }

    &__icon {
        position: relative;
        transition-duration: 400ms;

        &:before, &:after {
            display: block;
            content: '';
            background: #B68C87;
        }

        &:before {
            height: 13px;
            width: 1px;
            position: relative;
            left: 6px;
        }

        &:after {
            height: 1px;
            width: 13px;
            position: relative;
            top: -7px;
        }

        .active & {
            transform: rotate(360deg);

            &:before {
                opacity: 0;
            }
        }
    }

    &__answer {
        height: 0;
        overflow: hidden;

        .active & {
            height: auto;
        }

        p {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 26px;

            &:first-child {
                margin-top: 30px;
            }

            &:last-child {
                margin-bottom: 30px;
            }
        }

        ul, li {
            font-size: 18px !important;
            font-weight: $WEIGHT_LIGHT;
            color: #B68C87;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0px;
            margin: 0;
        }

        ul {
            margin-bottom: 10px;
        }
    }

    @include tablet {
        padding: 30px 15px;

        #{$parent}__title {
            margin-bottom: 30px;
        }
    }
}