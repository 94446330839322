.testimonials {
    padding: 80px 0;

    &__container {
        position: relative;
    }

    &__testimonial {
        padding: 20px;
        max-width: 933px;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        @include tablet {
            padding: 0;
        }
    }

    &__icon {
        margin-bottom: 20px;
    }

    &__text {
        color: #B68C87;
        font-size: 18px;
        font-weight: $WEIGHT_MEDIUM;
        margin-bottom: 20px;
        line-height: 26px;

        &--more {
            display: none;

            .active & {
                display: block;
            }
        }
    }

    &__name {
        color: #B68C87;
        font-size: 18px;
        text-transform: uppercase;
    }

    &__pagination {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        margin-top: 40px;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;

        &--next {
            left: 15px;
        }

        &--prev {
            right: 15px;
        }

        @include tablet {
            transform: none;
            top: auto;
            bottom: -10px;
        }
    }

    &__more {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 18px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        color: #B68C87;
        text-decoration: underline;

        .active & {
            display: none;
        }

        &--less {
            display: none;

            .active & {
                display: inline-block;
            }
        }
    }
}