.image {
    padding: 30px 0;
    background: $BG_PRIMARY;

    &__text {
        max-width: 349px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 70px;
        padding-top: 40px;

        @include tablet {
            padding-top: 20px;
            padding-bottom: 50px;
        }

        h1, h2, h3, h4, h5 {
            font-size: 26px;
            margin-top: 0;
            margin-bottom: 24px;
        }
    }
}