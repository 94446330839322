a {
    text-decoration: none;

    &:focus, &:active {
        outline: none;
    }

    &:visited {
        color: inherit;
    }
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style-type: none;
}

button {
    &:focus, &:active {
        outline: none;
        border: none;
    }
}