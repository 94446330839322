@mixin hover {
    &:hover {
        @media(min-width: 991px) {
            @content;
        }
    }
}

@mixin above-large-desktop {
@media (min-width: #{$size-large-desktop + 1}) {
    @content;
}
}

@mixin large-desktop {
@media (max-width: #{$size-large-desktop}) {
    @content;
}
}

@mixin above-medium-desktop {
@media (min-width: #{$size-medium-desktop + 1}) {
    @content;
}
}

@mixin medium-desktop {
@media (max-width: #{$size-medium-desktop}) {
    @content;
}
}

@mixin above-small-desktop {
@media (min-width: #{$size-small-desktop + 1}) {
    @content;
}
}

@mixin small-desktop {
@media (max-width: #{$size-small-desktop}) {
    @content;
}
}
@mixin above-container {
@media (min-width: #{$size-container + 1}) {
    @content;
}
}

@mixin container {
@media (max-width: #{$size-container}) {
    @content;
}
}
@mixin above-container-padding {
@media (min-width: #{$size-container + $spacing-border + 1}) {
    @content;
}
}

@mixin container-padding {
@media (max-width: #{$size-container + $spacing-border}) {
    @content;
}
}

@mixin above-tablet {
@media (min-width: #{$size-tablet + 1}) {
    @content;
}
}

@mixin tablet {
@media (max-width: #{$size-tablet}) {
    @content;
}
}

@mixin above-mobile-large {
@media (min-width: #{$size-mobile-large + 1}) {
    @content;
}
}

@mixin mobile-large {
@media (max-width: #{$size-mobile-large}) {
    @content;
}
}

@mixin above-mobile {
@media (min-width: #{$size-mobile + 1}) {
    @content;
}
}

@mixin mobile {
@media (max-width: #{$size-mobile}) {
    @content;
}
}

@mixin above-mobile-small {
@media (min-width: #{$size-mobile-small + 1}) {
    @content;
}
}

@mixin mobile-small {
@media (max-width: #{$size-mobile-small}) {
    @content;
}
}

@mixin max-width($width) {
@media(max-width: $width) {
    @content;
}
}

@mixin min-width($width) {
@media(min-width: $width) {
    @content;
}
}


@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin flexbox($important: false) {
    @if $important == true {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    } @else {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

@mixin inline-flex($important: false) {
    @if $important == true {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-flex !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    } @else {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($shrink) {
-webkit-flex-shrink: $shrink;
-moz-flex-shrink: $shrink;
-ms-flex-shrink: $shrink;
flex-shrink: $shrink;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
    $fg-boxflex: $fg;
    @if type-of($fg) == 'list' {
        $fg-boxflex: nth($fg, 1);
    }
    -webkit-box-flex: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg-boxflex;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-wrap($wrap: wrap) {
-webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
        flex-wrap: $wrap;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin no-select {
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

@mixin placeholder {
&::-webkit-input-placeholder {@content;}
&:-moz-placeholder           {@content;}
&::-moz-placeholder          {@content;}
&:-ms-input-placeholder      {@content;}
&::placeholder              {@content;}
}

@mixin position($position, $pos, $vals) {
position: $position;
@each $p in $pos {
    $i: index($pos, $p);
    #{$p}: nth($vals, $i);
}
}

@mixin transform($args...) {
-webkit-transform: $args;
-moz-transform: $args;
-ms-transform: $args;
-o-transform: $args;
transform: $args;
}

@mixin transition($args...) {
-webkit-transition: $args;
-moz-transition: $args;
-ms-transition: $args;
-o-transition: $args;
transition: $args;
}

@mixin transform-origin($args...) {
-webkit-transform-origin: $args;
-moz-transform-origin: $args;
-ms-transform-origin: $args;
-o-transform-origin: $args;
transform-origin: $args;
}

@mixin noscrollbar{
-ms-overflow-style: none;
&::-webkit-scrollbar {
    width: 0px;
    height: 0;
    padding: 0;
    display: none;
    margin: 0;
    border: none;
    background: transparent;
}
}

@mixin no-highlight {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin plain-number-input {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@mixin appearance($properties) {
appearance: $properties;
-moz-appearance: $properties;
-webkit-appearance: $properties;
}

@mixin box-shadow($properties) {
    -webkit-box-shadow: $properties;
    -moz-box-shadow: $properties;
    box-shadow: $properties;
}

@mixin background($size) {
    background-position: center;
    background-size: $size;
    background-repeat: no-repeat;
}
