.social-links {
    @include flexbox;
    @include justify-content(flex-start);
    width: 100%;

    &__link {
        @include flexbox;
        @include align-items(center);
        position: relative;
        margin-right: 50px;

        svg {
            position: relative;
            z-index: 2;
        }

        &:after {
            background: $BG_PRIMARY;
            opacity: 0;
            content: "";
            left: 0;
            right: 0;
            height: 10px;
            width: 10px;
            position: absolute;
            margin: 0 auto;
            display: block;
            border-radius: 50%;
            transition-duration: 500ms;
            z-index: 1;
        }

        @include hover {
            &:after {
                opacity: 1;
                @include transform(scale(5));
            }
        }
    }

    &--header {
        max-width: 188px;

        @include tablet{
            display: none;
        }
    }

    &--footer {
        max-width: 102px;
    }

    &--mobilemenu {
        max-width: 188px;
        margin: 0 auto;
        padding: 50px 0;

        opacity: 0;
        transition-duration: 1.5s;
        transition-delay: 200ms;

        @include mobile {
            padding: 25px 0;
        }

        .active & {
            opacity: 1;
        }
    }
}