.before-after {
    $parent: '&';

    position: relative;
    background: $BG_PRIMARY;
    padding: 80px 0;

    &__container {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        margin-bottom: 20px;
        position: relative;
    }

    &__control {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        width: 60px;
        margin: 60px auto 0 auto;
    }

    &__arrow {
        svg {
            stroke: #B68C87;
        }

        &.swiper-button-disabled {
            svg {
                stroke: #C7C7C7;
            }
        }
    }

    &__title {
        text-transform: uppercase;
        color: #B68C87;
        font-size: 26px;
    }

    &__image-wrap {
        @include flexbox;
    }

    &__image {
        padding-top: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition-duration: 1s;

        .before-after__image-wrap--single & {
            width: 100%;
        }
    }

    &__description {
        font-size: 16px;
        color: #B68C87;
        margin-top: 20px;
    }

    @include tablet {
        padding: 40px 0;

        #{$parent}__title {
            font-size: 18px;
        }
    }

    &__slide {
        transition-duration: 500ms;

        &.swiper-slide-next {
            transform: scale(1.2);
        }

    }

    &__slider {
        padding: 60px 0;
    }
}